<ih-basic-dialog>
  <ng-container ih-header> Send IHUBApp feedback</ng-container>
  <ng-container ih-content>
    <form id="feedbackForm" [formGroup]="feedbackForm" (ngSubmit)="submit()">
      <div class="fx-layout-column">
        <div class="feedback-prompt">
          We appreciate you taking the time to give us feedback. With your help we can focus our efforts to make your
          experience better.
        </div>
        <div class="summary-container">
          <ih-froala
            cdkFocusInitial
            contentType="contact"
            [height]="150"
            placeholder="Need help? Have a great idea? Let us know...."
            name="body"
            id="summary"
            [toolbar]="froalaToolbar"
            [mobileToolbar]="froalaToolbar"
            [toolbarStickyOffset]="55"
            [allowFileUpload]="false"
            formControlName="summary"
          >
          </ih-froala>
          <small class="text-danger" *ngIf="feedbackForm.controls['summary'].hasError('required') && formSubmitted">
            Please tell us about your experience.
          </small>
        </div>
        <mat-checkbox formControlName="allowBrowserInfo">
          Include browser data to help us troubleshoot issues.
        </mat-checkbox>
      </div>
    </form>
  </ng-container>
  <ng-container ih-footer>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="accent" type="submit" form="feedbackForm">Submit</button>
  </ng-container>
</ih-basic-dialog>
