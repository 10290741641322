import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { ADMIN_FROALA_TOOLBAR } from '@ih/constants';
import { FroalaComponent } from '@ih/froala';
import { ToSrcSetPipe } from '@ih/pipes';
import { SafePipe } from '@ih/safe-pipe';
import { EmojiService, LazySnackBarService } from '@ih/services';
import { FromNowPipe } from '@ih/time';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'ih-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,

    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatToolbarModule,
    ReactiveFormsModule,

    BasicDialogComponent,
    FroalaComponent,
    FromNowPipe,

    SafePipe,

    ToSrcSetPipe
  ]
})
export class FeedbackDialogComponent implements OnDestroy {
  @HostBinding('class.ih-feedback-dialog') hostClass = true;

  private mdDialogRef = inject(MatDialogRef<FeedbackDialogComponent>);
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private emoji = inject(EmojiService);
  private cd = inject(ChangeDetectorRef);

  feedbackForm = new UntypedFormGroup({
    destination: new UntypedFormControl('inspirehub', Validators.required),
    summary: new UntypedFormControl('', Validators.required),
    mood: new UntypedFormControl(null),
    allowBrowserInfo: new UntypedFormControl(false)
  });

  formSubmitted = false;

  private destroy$ = new Subject<void>();

  froalaToolbar = ADMIN_FROALA_TOOLBAR;

  constructor() {
    this.feedbackForm.controls['summary'].statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setMood(mood: string): void {
    this.emoji.init().then(() => {
      this.feedbackForm.controls['mood'].setValue(this.emoji.replaceColons(`:${mood}:`, undefined, 'native'));
    });
  }

  submit(): void {
    this.cd.markForCheck();

    this.formSubmitted = true;

    if (this.feedbackForm.valid) {
      this.mdDialogRef.close();
      this.snackbar.open('Submitting feedback...');

      this.http.post('/api/feedback', this.feedbackForm.value).subscribe(
        () => {
          this.snackbar.open('Feedback submitted');
          this.mdDialogRef.close();
        },
        () => {
          this.snackbar.open('Unable to submit your feedback.', 'SEND EMAIL').then((ref) =>
            ref.onAction().subscribe(() => {
              window.open('mailto:support@inspirehub.com?subject=Feedback%20form%20is%20not%20working', '_blank');
            })
          );
        }
      );
    }
  }

  cancel(): void {
    this.mdDialogRef.close();
  }
}
